import React from "react"

import Layout from "src/components/layout"
import { FaEnvelope, FaLinkedin, FaGithub } from 'react-icons/fa';

const Home = () => (
  <Layout>
    <div className="center top-padding">
      <p>Under construction</p>
    </div>
  </Layout>
)

export default Home
